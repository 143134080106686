.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: transparent;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 30px;
  background: #000;
  cursor: pointer;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #000;
  cursor: pointer;
}

.progressBar {
  transition-property: none; 
  transition-duration: 0s; 
}
