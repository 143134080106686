.skillsContainer {
  padding: 2rem 1em;
}

.skillsHeader {
  text-align: center;
  /* margin-bottom: 2rem; */
  font-size: 2rem;
  color: #333;
}

.skill {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #666;
}
