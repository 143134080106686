.contactButton {
  background-color: #121213;
  border-radius: 30px;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.intro {
  font-size: 1.5rem;
  max-width: 600px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}


.main {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
